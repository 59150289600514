import React, { ReactElement, Fragment, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { Button } from 'src/ui/components';
import { Tooltip } from 'src/ui/components/Tooltip';

import { ReadyToChatIcon, NotReadyToChatIcon } from 'src/assets/icons/index';

import { useAuth } from 'src/hooks';

import { calculateAgeAsString, createMarkup } from 'src/utils/common';
import { calllimitWarningMsg } from '../utils/personAlertConstants';
import * as utilityFn from 'src/utils/common';

import {
  PersonAlertsAccountsDetails,
  PersonAlertsReasonType,
  PhoneNumberType,
} from 'src/features/AddEditPersonAlert/types/personAlertsAccounts';
import { PersonAlertListType } from '../types/personAlerts';

type PreviewModalType = {
  documentPreviewModal: boolean;
  setDocumentPreviewModal: (
    value: boolean | ((prevState: boolean) => boolean),
  ) => void;
  previewData: PersonAlertsAccountsDetails | undefined;
  setSelectedAlert: (
    value:
      | PersonAlertListType
      | undefined
      | ((
          prevState: PersonAlertListType | undefined,
        ) => PersonAlertListType | undefined),
  ) => void;
  countryList: Array<any> | [];
  reqAlertId: string | undefined | null;
};

function PreviewModal({
  documentPreviewModal,
  setDocumentPreviewModal,
  previewData,
  setSelectedAlert,
  countryList,
  reqAlertId,
}: PreviewModalType) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isExternalUser } = useAuth();

  const reasons = useMemo(() => {
    let val = previewData?.Reasons?.map(
      (item: PersonAlertsReasonType) => item?.Description,
    );
    return val?.join(', ');
  }, [previewData?.Reasons]);

  //for print preview functionality
  function onPrintReport() {
    window.open(
      `PersonAlert/PrintPreview/${previewData?.Id}?accountId=${previewData?.Person?.AccountId}`,
    );
  }

  function handleOnClose() {
    queryClient.invalidateQueries(['get_person_alert_preview'], {
      refetchActive: false,
    });
    setDocumentPreviewModal(false);
    setSelectedAlert(undefined);
    if (reqAlertId) navigate('/PersonAlert');
  }

  const countryCode = useMemo(() => {
    if (countryList) {
      return utilityFn.countryOptions(countryList);
    }
  }, [countryList]);

  function getCountryCode(countryId: number | undefined) {
    if (countryId)
      return countryList?.find((item: any) => item?.Id === countryId)?.ISDCode;
    else return '+1';
  }

  function getFormattedNumber(phoneInfo: PhoneNumberType | undefined) {
    if (phoneInfo && phoneInfo?.Number) {
      const isUsFormat = utilityFn.hasUsFormat(
        countryCode,
        phoneInfo?.CountryId,
      );
      const phonenumber = utilityFn.formatPhoneNumber(
        phoneInfo?.Number,
        isUsFormat,
      );
      return phonenumber;
    }
  }

  //render alert information
  function renderAlertInformation(): ReactElement {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Alert ID</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Id}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Entered by</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.EnteredBy}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Entered date</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.EnteredDate != null
              ? dayjs(previewData?.EnteredDate).format('MM/DD/YYYY HH:mm:ss')
              : ''}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Start/End date</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {previewData?.StartDate != null
                ? dayjs(previewData?.StartDate).format('MM/DD/YYYY HH:mm:ss')
                : ''}{' '}
              -{' '}
              {previewData?.EnteredDate != null
                ? dayjs(previewData?.EndDate).format('MM/DD/YYYY HH:mm:ss')
                : ''}
            </p>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Reason for Alert</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>{reasons}</p>
          </div>
        </dd>
      </dl>
    );
  }

  // render primary & secondary phone information

  function renderPhoneInfo(
    phoneInfo: PhoneNumberType | undefined,
    label: string,
  ): ReactElement {
    return (
      <dd className="flex gap-2  border-b dark:border-dark-medium">
        <h4 className="w-1/3 p-2 text-sm font-medium">{label}</h4>
        <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
          {/* {phoneInfo?.Number !== null && ( */}
          <>
            <p>
              ( {getCountryCode(phoneInfo?.CountryId)} ) {''}{' '}
              {/* {phoneInfo?.Number}{' '} */}
              {getFormattedNumber(phoneInfo)}
              {phoneInfo?.OkToLeaveMessage === true ? (
                <ReadyToChatIcon className="ms-1 text-green-700 dark:text-green-400" />
              ) : (
                phoneInfo?.OkToLeaveMessage === false && (
                  <NotReadyToChatIcon className="ms-1 text-red-700 dark:text-red-400" />
                )
              )}
            </p>
            {phoneInfo?.Comments && phoneInfo?.Comments?.length > 0 && (
              <p>
                <label className="text-sm font-medium">Comments: </label>
                {phoneInfo?.Comments}
              </p>
            )}
          </>
          {/* )} */}
        </div>
      </dd>
    );
  }

  //render person information
  function renderPersonInformation(): ReactElement {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Person information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">First name</h4>
          <p className="w-4/6  border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Person?.FirstName}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Last name</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Person?.LastName}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Alternate name</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Person?.PreferredName}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Address</h4>
          <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            <p>
              {previewData?.Person?.PersonAlertDetails?.PrimaryAddress?.Street1}
            </p>
            <p>
              {previewData?.Person?.PersonAlertDetails?.PrimaryAddress?.Street2}
            </p>
            <p>
              {previewData?.Person?.PersonAlertDetails?.PrimaryAddress?.Street3}
            </p>
            <p>
              {previewData?.Person?.PersonAlertDetails?.PrimaryAddress?.City}
            </p>
            <p>
              {
                previewData?.Person?.PersonAlertDetails?.PrimaryAddress
                  ?.ZipPostalCode
              }
            </p>
          </div>
        </dd>
        {renderPhoneInfo(
          previewData?.Person?.PersonAlertDetails?.PrimaryPhone,
          'Primary phone',
        )}
        {renderPhoneInfo(
          previewData?.Person?.PersonAlertDetails?.SecondaryPhone,
          'Secondary phone',
        )}

        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Gender</h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Person?.Gender?.Description}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">Date of Birth</h4>

          <div className="flex w-4/6 gap-3 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.Person?.PersonAlertDetails?.Birthdate != null && (
              <p>
                <label className="text-sm font-medium">Birth date: </label>
                {dayjs(
                  previewData?.Person?.PersonAlertDetails?.Birthdate,
                ).format('MM/DD/YYYY')}
              </p>
            )}

            {previewData?.Person?.PersonAlertDetails?.Age !== null &&
            previewData?.Person?.PersonAlertDetails?.Age !== '' ? (
              <div>
                <label className="text-sm font-medium">Age: </label>
                <span>
                  {previewData?.Person?.PersonAlertDetails?.Age !== null
                    ? previewData?.Person?.PersonAlertDetails?.Age
                    : calculateAgeAsString(
                        previewData?.Person?.PersonAlertDetails?.Birthdate ||
                          '',
                      )}
                </span>
              </div>
            ) : null}
          </div>
        </dd>
      </dl>
    );
  }

  //render other information
  function rednerOtherInformation(): ReactElement {
    return (
      <dl className="border dark:border-dark-medium">
        <dt className="sticky -top-px border-b bg-gray-100 px-3 py-2 text-xl font-semibold dark:border-dark-medium dark:bg-neutral-900">
          Information
        </dt>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Counselor or Case manager (if applicable)
          </h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.CounselorOrCaseManager}
          </p>
        </dd>
        <dd className="flex gap-2 border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Special program participant
          </h4>
          <p className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
            {previewData?.SpecialProgramsParticipant}
          </p>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Situation description
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 dark:border-l-dark-medium">
            <div
              className="prose max-w-full dark:prose-invert"
              dangerouslySetInnerHTML={createMarkup(
                previewData?.SituationDescription || '',
              )}
            ></div>
          </div>
        </dd>
        <dd className="flex gap-2  border-b dark:border-dark-medium">
          <h4 className="w-1/3 p-2 text-sm font-medium">
            Telephone intervention description
          </h4>
          <div className="w-4/6 border-l border-l-light-light p-2 dark:border-l-dark-medium">
            <div
              className="prose max-w-full dark:prose-invert"
              dangerouslySetInnerHTML={createMarkup(
                previewData?.TelephoneInterventionDescription || '',
              )}
            ></div>
          </div>
        </dd>
        {previewData?.Person?.PersonCallLimit !== null && !isExternalUser && (
          <dd className="flex gap-2  border-b dark:border-dark-medium">
            <h4 className="flex w-1/3 items-center gap-1 p-2 text-sm font-medium">
              <span className="leading-none">Call limit</span>

              <Tooltip
                trigger={
                  <InformationCircleIcon
                    className="-mb-0.5 h-5 w-5 text-primary"
                    aria-hidden="true"
                  />
                }
                content={calllimitWarningMsg}
              />
            </h4>
            <div className="w-4/6 border-l border-l-light-light p-2 text-sm dark:border-l-dark-medium">
              <p>
                Caller made{' '}
                {previewData?.Person?.PersonCallLimit?.CallsCalled ?? 0} out of{' '}
                {previewData?.Person?.PersonCallLimit?.MaxCallCount} allowed
                calls
              </p>
            </div>
          </dd>
        )}
      </dl>
    );
  }

  return (
    <Transition appear show={documentPreviewModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => handleOnClose()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pr-dialog__backdrop" />
        </Transition.Child>

        <div className="pr-dialog__wrapper">
          <div className="pr-dialog__panel__container  sm:!grid-rows-[1fr_auto_1fr]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{ maxHeight: 'calc(100svh - 150px)' }}
                className="pr-dialog__panel flex flex-col sm:!max-w-5xl"
              >
                <div className="flex items-center gap-3 border-b p-4 dark:border-dark-medium">
                  <Dialog.Title
                    as="h3"
                    className="grow truncate text-lg font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Person alert
                  </Dialog.Title>

                  <button
                    type="button"
                    className=" text-gray-400 hover:text-gray-500 sm:right-4 sm:top-4"
                    onClick={() => handleOnClose()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="relative min-h-0 grow overflow-auto scroll-smooth px-4 pb-4">
                  <div className="min-w-[400px] space-y-6">
                    {renderAlertInformation()}
                    {renderPersonInformation()}
                    {rednerOtherInformation()}
                  </div>
                </div>
                <div className="flex flex-col gap-[1ch] border-t bg-gray-50 p-2 dark:border-dark-medium dark:bg-neutral-700/50 sm:flex-row sm:justify-end sm:p-4">
                  <Button
                    variant="secondary"
                    onClick={() => handleOnClose()}
                    className="grow sm:grow-0"
                  >
                    Cancel
                  </Button>
                  {reqAlertId === undefined && (
                    <Button
                      variant="primary"
                      onClick={() => onPrintReport()}
                      className="grow sm:grow-0"
                    >
                      Print
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export { PreviewModal };
